import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { UsersService } from "../../../services/users/users.service";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { filter } from 'rxjs/operators';
import { ActivatedRoute, Router } from "@angular/router";
import { forkJoin } from "rxjs";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import * as moment from "moment";
import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { StudyData } from "src/app/shared/interfaces/data.interface";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { Route } from "@angular/compiler/src/core";
import * as ExcelJS from 'exceljs';
@Component({
  selector: 'app-detail-history-clinic',
  templateUrl: './detail-history-clinic.component.html',
  styleUrls: ['./detail-history-clinic.component.scss']
})
export class DetailHistoryClinicComponent {

  @ViewChild('tabset') tabset: any;
  loading = true;
  downloadFile = false;
  age: number;
  arrayData: any;
  arrayDataUser: any;
  currentUserId: any;
  clickInputBoolean: boolean = false;
  currentUser: any;
  arrayFamiliares: any;
  public optionSelected: string[] = [];
  collaborador: any;
  studies: StudyData[] = [];
  works: any[] = [];
  arrayFactores: any;
  rallyData: any;
  modalConfig: NgbModalOptions = {
    ariaLabelledBy: 'modal-basic',
    windowClass: 'myCustomModalClass'
  };
  workSelected: any;
  historyReason: string = "0";
  onSee: boolean = false;
  historyData: any;
  peso: number = 0;
  estatura: number = 0;
  cercaAnswers: any;
  lejosAnswers: any;
  sentidoQuestion: any;
  visionQuestion: any;
  reflejosQuestion: any;
  extremidadesQuizes: any = [];
  otherQuizes: any = [];

  constructor(
    private usersService: UsersService,
    private route: ActivatedRoute,
    private router: Router,
    private _usersService: UsersService,
    private zone: NgZone,
    private _snackBar: MatSnackBar,
    private modalService: NgbModal,
  ) {

    this.currentUserId = this.route.snapshot.paramMap.get('id');

    this.loadData(-1);
    this._usersService.getCatalogFamiliar().subscribe(data => {
      this.arrayFamiliares = data;
    })

    this._usersService.getCatalogFactor().subscribe(data => {
      this.arrayFactores = data;
    })
  }

  get imc() {
    if (this.peso <= 0 || this.estatura <= 0) {
      return 0;
    }

    const imc = this.peso / (this.estatura * this.estatura);
    if (isNaN(imc)) return 0;
    return imc.toFixed(2);
  }

  generateIMC(question: any) {
    if (question.Question.includes("Peso")) {
      if (question.Answer !== "") {
        this.peso = parseFloat(question.Answer);
      }
    }
    if (question.Question.includes("Talla")) {
      if (question.Answer !== "") {
        this.estatura = parseFloat(question.Answer);
      }
    }
  }

  openContentWork(content) {
    this.addWork();
    this.workSelected = this.works[this.works.length - 1];
    this.modalService.open(content, this.modalConfig);
  }

  setResponseFactor(event: any, qindex: any, index: any) {
    const quiz = this.workSelected;
    const value = event.target.value;
    if (!quiz.agentes.includes(value)) {
      quiz.agentes.push(value);
    } else {
      const index = quiz.agentes.indexOf(value);
      quiz.agentes.splice(index, 1);
    }
  }

  setResponseFamiliares(event: any, question: any) {
    const value = event.target.value;

    if (!question.Answerback) {
      question.Answerback = [value];
    } else {
      if (!question.Answerback.includes(value)) {
        question.Answerback.push(value);
      } else {
        const index = question.Answerback.indexOf(value);
        question.Answerback.splice(index, 1);
      }
    }
  }

  setResponse(event: any, qindex: any, index: any) {
    console.log(event);
    const quiz = this.arrayData[qindex];
    console.log(event.target.value);
    const response = event.target.value.split("-");
    const question = quiz.questions[index];
    question.Answer = response[0];
    question.AnswerId = response[1] || null;
  }

  setResponseRally(event: any, stage: any, quiz: any, question: any) {
    const quizData = this.rallyData.stages[stage].quizes[quiz];
    const value = event.target.value.split(" - ");
    quizData.questions[question].Answer = value[0];
    quizData.questions[question].AnswerId = value[1];
    quizData.questions[question].Answerback = '';
  }

  submitRally(stage: number) {

    const userResponse = [];
    const stageData = this.rallyData.stages[stage];

    if (stageData.quizes[0].questions[0].ResultId) {
      stageData.quizes.forEach((quiz: any, index2: number) => {
        quiz.questions.forEach((question: any, index: number) => {
          const response = {
            AnswerId: parseInt(question.AnswerId),
            id: question.ResultId,
            Answers: ''
          }
          response.Answers = question.Answer === 'Sí' || question.Answer === 'Anormal' ? `${question.Answer} - ${question.Answerback}` : question.Answer
          userResponse.push(response);
        });
      });

      this._usersService.updateQuizAnswers(userResponse).subscribe(data => { });

    } else {
      const quizIds = stageData.quizes.map((q: any) => { return q.id });
      stageData.quizes.forEach((quiz: any, index2: number) => {
        quiz.questions.forEach((question: any, index: number) => {
          const response = {
            QuizId: quiz.id,
            QuestionId: question.id,
            AnswerId: question.AnswerId,
            Answers: ''
          }
          response.Answers = question.Answer === 'Sí' || question.Answer === 'Anormal' ? `${question.Answer} - ${question.Answerback}` : question.Answer
          userResponse.push(response);
        });
      });

      for (let i = 0; i < quizIds.length; i++) {
        const quizesResponse = userResponse.filter((response: any) => response.QuizId === quizIds[i]);
        const body = {
          UserId: this.currentUserId,
          QuizId: quizIds[i],
          Comments: stageData.quizes[i].comments,
          answers: quizesResponse,
        }

        this._usersService.addHistoryClinic(body).subscribe(data => { });
      }
    }

    setTimeout(() => {
      this.addToast("Datos guardados correctamente", 'success-snackbar');
      this.loadData(4);
    }, 1500);
    //}
  }

  async loadData(index: number) {

    const data2 = await this.usersService.getCollaborator(this.currentUserId).toPromise();
    const historyId = this.route.snapshot.paramMap.get('historyId');

    if (historyId) {
      this.onSee = true;
      this.historyData = await this.usersService.getHistoryData(historyId).toPromise()
      this.historyReason = this.historyData.reason;
    }
    this.collaborador = data2;
    const birthdayMoment = moment(data2.dateOfBirth);
    this.age = moment().diff(birthdayMoment, 'years');

    const arrData = [1, 3, 2, 6, 19];
    if (data2.gender === 'femenino') arrData.splice(3, 0, 4);
    const observables2 = arrData.map((element: any) => {
      return this._usersService.getQuiz(element)
    });

    forkJoin(observables2).subscribe((results: any) => {
      this.arrayData = [];
      results.forEach((data: any) => {
        data.comments = '';
        data.questions = data.questions.map((question: any) => {
          if (question.Type === 'radio' || question.Type === 'other') {
            let index = question.answers.find((answer: any) => answer.Answer === 'No');
            if (index) {
              question.Answer = index.Answer;
              question.AnswerId = index.id;
              question.Answerback = data.Title.includes('Familiares') ? [] : null;
            }
            index = question.answers.find((answer: any) => answer.Answer === 'Normal');
            if (index) {
              question.Answer = index.Answer;
              question.AnswerId = index.id;
              question.Answerback = data.Title.includes('Familiares') ? [] : null;
            }
            index = question.answers.find((answer: any) => answer.Answer === 'Sin alteraciones');
            if (index) {
              question.Answer = index.Answer;
              question.AnswerId = index.id;
              question.Answerback = data.Title.includes('Familiares') ? [] : null;
            }
            index = question.answers.find((answer: any) => answer.Answer === 'Completos');
            if (index) {
              question.Answer = index.Answer;
              question.AnswerId = index.id;
              question.Answerback = data.Title.includes('Familiares') ? [] : null;
            }
          } else {
            question.AnswerId = null;
            question.Answer = null;
          }
          question.AnswerSelected = 0;
          question.AnswerCorrect = null;
          return question;
        });
        this.arrayData.push(data);
      });
    });

    this.rallyData = await this.usersService.getExplorationPhysic(1).toPromise();
    const stageData = this.rallyData.stages[0];

    stageData.quizes.sort((a, b) => a.Order - b.Order);


    stageData.quizes.forEach((q: any) => {
      q.comments = '';
      q.questions = q.questions.map((question: any) => {
        if (question.Type === 'radio' || question.Type === 'other') {
          let index = question.answers.find((answer: any) => answer.Answer === 'No');
          if (index) {
            question.Answer = index.Answer;
            question.AnswerId = index.id;
            question.Answerback = null;
          }
          index = question.answers.find((answer: any) => answer.Answer === 'Normal');
          if (index) {
            question.Answer = index.Answer;
            question.AnswerId = index.id;
            question.Answerback = null;
          }
          index = question.answers.find((answer: any) => answer.Answer === 'Sin alteraciones');
          if (index) {
            question.Answer = index.Answer;
            question.AnswerId = index.id;
            question.Answerback = null;
          }
          index = question.answers.find((answer: any) => answer.Answer === 'Completos');
          if (index) {
            question.Answer = index.Answer;
            question.AnswerId = index.id;
            question.Answerback = null;
          }
        } else {
          question.AnswerId = null;
          question.Answer = null;
        }
        question.AnswerSelected = 0;
        question.AnswerCorrect = null;
        return question;
      });
    });

    let data = await this.usersService.getQuizsByUser(this.currentUserId).toPromise();
    if (historyId) {
      data = await this.usersService.getQuizsByHistory(historyId).toPromise();
    }
    this.arrayDataUser = data;
    const observables = this.arrayDataUser.map((element: any) => {
      return this.usersService.getAnswersByQuiz(element?.id);
    });

    forkJoin(observables).subscribe((results: any) => {
      results.forEach((data: any) => {
        const index = this.arrayData.findIndex((ad: any) => ad.id === data.quiz.id);
        if (index !== -1) {
          this.arrayData[index].comments = data.comments;
          this.arrayData[index].answers = data.answers;
          for (const answer of data.answers) {
            const questions = this.arrayData[index].questions;
            const question = questions.find((q: any) => q.id === answer.QuestionId);
            question.AnswerId = answer.AnswerId;
            question.ResponseId = answer.ResponseId;
            if (answer.Answer) {

              const response = answer.Answer.split(" - ");
              question.Answer = response[0].trim();
              question.AnswerSelected = response[1] ? response[1].trim() : null;
              question.AnswerCorrect = true;
              if (!this.arrayData[index].Title.includes('Familiares')) {
                question.Answerback = response[1] || '';
              } else {
                question.Answerback = response[1] ? response[1].split(",") : null;
              }
              if (question.Question === 'Peso') {
                this.peso = question.Answer;
              }
              if (question.Question === 'Estatura') {
                this.estatura = question.Answer;
              }
            }
          }

          if (data.quiz.Title.includes('Antropometríco') || data.quiz.Title.includes('Laboratorio')) {
            this.otherQuizes.push(this.arrayData[index]);
          }

        } else {
          const newIndex = stageData.quizes.findIndex((ad: any) => ad.id === data.quiz.id);
          const quiz = stageData.quizes[newIndex];

          if (quiz.Description.includes('Extremidades')) {
            this.extremidadesQuizes.push(quiz);
          }

          if (quiz.Description.includes("Agudeza Visual")) {
            const cerca = quiz.questions.filter((q) => q.Question.includes('derecho'));
            const lejos = quiz.questions.filter((q) => q.Question.includes('izquierdo'));
            const correccion = quiz.questions.filter((q) => q.Question.includes('anteojos'));
            this.sentidoQuestion = quiz.questions.filter((q) => q.Question.includes('Sentido'))[0];
            this.visionQuestion = quiz.questions.filter((q) => q.Question.includes('Visión'))[0];
            this.reflejosQuestion = quiz.questions.filter((q) => q.Question.includes('Reflejos'))[0];
            cerca.push(correccion[0]);
            lejos.push(correccion[0]);
            this.cercaAnswers = cerca;
            this.lejosAnswers = lejos;
          }

          for (let i = 0; i < data.answers.length; i++) {
            const question = data.answers[i];
            const questionIdx = quiz.questions.findIndex((q: any) => q.id == question.QuestionId);
            quiz.comments = data.comments;
            quiz.questions[questionIdx].Answer = question.Answer ? question.Answer.split(" - ")[0] : '';
            quiz.questions[questionIdx].ResultId = question.ResponseId;
            quiz.questions[questionIdx].Answerback = question.Answer ? question.Answer.split(" - ")[1] : '';
          }
        }
      });
    });


    this.tabset.select('tab' + (index === -1 ? 0 : (index + 1)));
    window.scrollTo(0, 0);

    let dataFiles;
    if (historyId) {
      dataFiles = this.historyData.HistoryFiles;
    } else {
      dataFiles = await this.usersService.getCollaboratorFiles(this.currentUserId).toPromise();
    }
    if (dataFiles.length > 0) {
      this.studies = dataFiles.map((study: StudyData) => {
        study.saved = true;
        return study;
      })
    } else {
      this.studies = [];
      this.studies.push({ name: 'Certificado de Aptitud Médico Laboral', date: moment().format("DD/MM/Y"), file: '', saved: false, fileName: '', collaborator_id: this.currentUserId })
    }

    let dataExperiences;
    if (historyId) {
      dataExperiences = this.historyData.HistoryExperiences;
    } else {
      dataExperiences = await this.usersService.getCollaboratorExperience(this.currentUserId).toPromise();
    }
    this.works = dataExperiences.map((work: any) => {
      work.agentes = work.agentes.split("-");
      work.saved = true;
      return work;
    });

    this.loading = false;

  }

  nextStep(index) {
    this.tabset.select('tab' + index);
    window.scrollTo(0, 0);
  }

  saveHistory() {
    if (this.historyReason === '0') {
      this.addToast('Debes especificar el tipo de examen', 'error-snackbar');
      return;
    }

    if (this.studies.some((study: any) => study.file === '')) {
      this.addToast('Hay archivos pendientes de cargar', 'error-snackbar');
      return;
    }

    const filesIds = this.studies.map((item: any) => { return item.id });
    const experienceIds = this.works.map((item: any) => { return item.id });
    const quizesIds = this.arrayDataUser.map((item: any) => { return item.id });
    const user = JSON.parse(localStorage.getItem('currentUser'));
    const body = {
      userId: user.id,
      reason: this.historyReason,
      elaborationDate: moment().format("Y-MM-DD"),
      cliniccollaborator_id: this.currentUserId,
      works: experienceIds,
      studies: filesIds,
      quizes: quizesIds
    };

    this.usersService.saveCollaboratorHistory(body).subscribe((data) => {
      this.addToast(data.Message, 'success-snackbar');
      this.router.navigate(['/colaboradores/history-clinic', this.currentUserId]);
    });

  }
  toggleLabel(id: string, q, i) {
    const name = `${id}${q}-${i}`;
    const select = document.getElementById(name) as any;
    const label = select.nextElementSibling;
    label.style.top = select.selectedIndex === 0 ? '50%' : '-11px';
    label.style.fontSize = select.selectedIndex === 0 ? '14px' : '12px';
    label.style.color = select.selectedIndex === 0 ? '#888' : '#888';
  }

  async onFileSelected(event: any, index: number) {
    const file: File = event.target.files[0];
    const maxSize = 1024 * 1024 * 10;
    if (file.size > maxSize) {
      this.addToast('Tus anexos no pueden pesar más de 10 MB', 'error-snackbar');
      return;
    }
    if (file) {
      const formData = new FormData();
      const filename = file.name.split(".");
      formData.append('file', file, filename.join(new Date().getTime() + '.'));
      formData.append('type', 'annexed');

      const resolve = await this._usersService.uploadFile(formData).toPromise();
      this.studies[index].file = resolve.ImageUrl;
      this.studies[index].fileName = resolve.ImageUrl.split('/').pop();
    }
  }

  async uploadImageFile(event: any) {
    const file: File = event.target.files[0];
    const maxSize = 1024 * 1024 * 10;
    if (file.size > maxSize) {
      this.addToast('Tus anexos no pueden pesar más de 10 MB', 'error-snackbar');
      return;
    }
    if (file) {
      const formData = new FormData();
      const filename = file.name.split(".");
      formData.append('file', file, filename.join(new Date().getTime() + '.'));
      formData.append('type', 'img');

      const resolve = await this._usersService.uploadFile(formData).toPromise();
      this.collaborador.imageFileUrl = resolve.ImageUrl;
      const body = {
        imageFileUrl: resolve.ImageUrl
      }
      this._usersService.editCollaborator(this.currentUserId, body).subscribe((data: any) => {
        this.addToast('Imagen cargada exitosamente', 'success-snackbar');
      })
    }
  }

  changeDate(event): void {
    const select = document.getElementById(event) as any;
    select.type = 'date';
    const label = select.nextElementSibling;
    label.style.top = '-21px';
    label.style.fontSize = '12px';
    label.style.color = '#888';
  }

  addStudy() {
    this.studies.push({ name: '', date: '', file: '', saved: false, fileName: '', collaborator_id: this.currentUserId });
  }

  addWork() {
    this.works.push({
      name: '',
      position: '',
      currentActivity: '',
      seniority: '',
      yearSeniority: '',
      monthSeniority: '',
      epp: '',
      enfermedad: '',
      saved: false,
      comments: '',
      padecimieto: '',
      collaborator_id: this.currentUserId
    });
  }

  saveStudy(index: number) {
    const study = this.studies[index];
    if (study.name === '' || study.date === '') {
      this.addToast('Debes llenar todos los campos', 'error-snackbar');
      return;
    }

    if (study.file === '') {
      this.addToast('Debes cargar el archivo para continuar', 'error-snackbar');
      return;
    }

    this._usersService.saveCollaboratorFile(this.currentUserId, study).subscribe((data) => {
      study.id = data.id;
      study.saved = true;
      this.addToast('Archivo guardado correctamente', 'success-snackbar');
    });

  }

  saveWork(index: number) {
    const work = this.workSelected;
    if (work.name === '' || work.position === '' || work.positionDescription === '' || work.yearSeniority === '') {
      this.addToast('Debes llenar todos los campos', 'error-snackbar');
      return;
    }
    work.seniority = `${work.yearSeniority} años ${work.monthSeniority || 0} meses`;
    this._usersService.saveCollaboratorExperience(this.currentUserId, work).subscribe((data) => {
      work.saved = true;
      work.id = data.id;
      this.modalService.dismissAll();
      this.addToast('Antecedente guardado correctamente', 'success-snackbar');
    });

  }

  clickInputToggle() {
    this.clickInputBoolean = !this.clickInputBoolean
  }

  setCorrect(event: any, qindex: any, index: any) {
    const quiz = this.arrayData[qindex];
    quiz.questions[index].AnswerCorrect = event.target.value !== '0';
  }

  async generateExcel() {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('HistoriaClinica');

    this.arrayData.forEach((data: any, index: number) => {
      const row = worksheet.addRow([data.Title]);
      row.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'ed7d31' },
        bgColor: { argb: 'ed7d31' }
      };
      row.font = { name: 'Arial', family: 4, size: 14, bold: true };
      data.questions.forEach((question: any) => {
        const answer = question.Type === 'radio' ? question.Answer.includes("Sí") || question.Answer.includes("Anormal") ? `${question.Answer} - ${question.Answerback}` : question.Answer : question.Answerback
        const newRow = worksheet.addRow([question.Question, question.Answer]);
      });
      const newRow = worksheet.addRow(['Comentarios', data.comments]);
    });

    this.rallyData.stages[0].quizes.forEach((data: any, index: number) => {
      const row = worksheet.addRow([data.Title]);
      row.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'ed7d31' },
        bgColor: { argb: 'ed7d31' }
      };
      row.font = { name: 'Arial', family: 4, size: 14, bold: true };
      data.questions.forEach((question: any) => {
        const answer = question.Type === 'radio' ? question.Answer.includes("Sí") || question.Answer.includes("Anormal") ? `${question.Answer} - ${question.Answerback}` : question.Answer : question.Answerback
        const newRow = worksheet.addRow([question.Question, question.Answer]);
      });
      const newRow = worksheet.addRow(['Comentarios', data.comments]);
    });

    let row = worksheet.addRow(['Antecedentes laborales']);
    row.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'ed7d31' },
      bgColor: { argb: 'ed7d31' }
    };
    row.font = { name: 'Arial', family: 4, size: 14, bold: true };
    this.works.forEach((work: any) => {
      const newRow = worksheet.addRow([work.name, work.position, work.positionDescription, work.agentes.join(", "), work.seniority, work.accident, work.accidentDescription]);
    });

    row = worksheet.addRow(['Expediente']);
    row.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'ed7d31' },
      bgColor: { argb: 'ed7d31' }
    };
    row.font = { name: 'Arial', family: 4, size: 14, bold: true };
    this.studies.forEach((work: any) => {
      const newRow = worksheet.addRow([work.name, work.date, work.file]);
    });

    worksheet.getColumn(1).width = 40;
    worksheet.getColumn(2).width = 40;
    worksheet.getColumn(3).width = 40;
    worksheet.getColumn(4).width = 40;
    worksheet.getColumn(5).width = 40;
    worksheet.getColumn(6).width = 40;
    worksheet.getColumn(7).width = 40;

    const blob = await workbook.xlsx.writeBuffer();

    // Crear una URL para el blob
    const blobURL = URL.createObjectURL(new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));

    // Crear un enlace para descargar el archivo
    const link = document.createElement('a');
    link.href = blobURL;
    const date = new Date();
    link.download = `${this.collaborador.firstName}-${date.getMilliseconds()}.xlsx`;

    // Agregar el enlace al documento y simular clic
    document.body.appendChild(link);
    link.click();

    // Limpiar el enlace del documento
    document.body.removeChild(link);
  }

  submitFormFamilia(q: any) {
    const quiz = this.arrayData[q];
    const answers = quiz.questions.map((q: any) => {
      const t = {
        QuestionId: q.id,
        AnswerId: q.AnswerId,
        AnswerCorrect: q.AnswerCorrect,
        ResponseId: q.ResponseId,
        Answers: ''
      }
      if (!quiz.Title.includes('Familiares')) {
        console.log(q);
        if (q.Type === 'text' || q.Type === 'select') {
          t.Answers = q.Answer
        } else {
          if (q.Type === 'radio') {
            if (q.Answerback) {
              t.Answers = q.Answerback !== null ? `${q.Answer} - ${q.Answerback}` : `${q.Answer}`;
            } else {
              t.Answers = q.Answer;
            }
          }
        }
      } else {
        console.log(q);
        if (q.Answer) {
          if (q.Answer.includes('Sí')) {
            t.Answers = `${q.Answer} - ${q.Answerback}`
          } else {
            t.Answers = q.Answer;
          }
        } else {
          t.Answers = '';
        }
      }
      return t;
    });

    if (!quiz.answers) {
      const body = {
        UserId: this.currentUserId,
        QuizId: quiz.id,
        Comments: quiz.comments,
        answers
      }

      this._usersService.addHistoryClinic(body).subscribe(data => {
        this.addToast("Datos guardados correctamente", 'success-snackbar');
        this.loadData(q < 3 ? q : q + 2);
      });

    } else {
      const answers2 = answers.map((ans) => {
        return {
          AnswerId: parseInt(ans.AnswerId),
          id: ans.ResponseId,
          Answers: ans.Answers
        }
      })
      this._usersService.updateQuizAnswers(answers2).subscribe(data => {
        this.addToast("Datos actualizados correctamente", 'success-snackbar');
        this.loadData(q < 3 ? q : q + 2);
      });
    }

  }


  generatePdf() {
    this.downloadFile = true;
    const contenidoPDF = document.getElementById('pdfId').innerHTML;
    const body = {
      html: contenidoPDF,
      historyId: this.historyData.id,
      collaboratorId: this.currentUserId
    };

    this._usersService.generatePdf(body).subscribe((data: any) => {
      if (data.type === 'application/json') {
        this.addToast('Verifica que el colaborador tenga fotografía o el usuario que creo la historia tenga firma cargada en el sistema', 'error-snackbar');
      } else {
        this.downloadFile = false;
        window.open(URL.createObjectURL(data), '_blank');
      }
    })
  }

  addToast(message: any, className: string) {
    this.zone.run(() => {
      this._snackBar.open(message, null, {
        duration: 5000,
        verticalPosition: 'top',
        horizontalPosition: 'center',
        panelClass: [className],
      });
    });
  }

}
