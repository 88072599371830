<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card-body tab2-card">
                <div class="px-3 pb-3">
                    <h4 class="px-2">{{ onEdit ? 'Editar empresa' : 'Nueva empresa' }}</h4>
                </div>
                <div class="card">
                    <form [formGroup]="accountForm"
                        class="needs-validation user-add custom-edit-enterprise-padding py-4" novalidate>
                        <div class="form-group row">
                            <div class="col-12 col-md-6 input-container">
                                <input class="input-field" formControlName="FirstName" type="text" placeholder="00"
                                    [ngClass]="{'error': accountForm.controls['FirstName'].touched && accountForm.controls['FirstName'].errors?.required}"
                                    required>
                                <span
                                    *ngIf="accountForm.controls['FirstName'].touched && accountForm.controls['FirstName'].errors?.required"
                                    class="text-danger">
                                    Nombre es requerido.
                                </span>
                                <label class="input-label">Nombre(s)</label>
                            </div>
                            <div class="col-12 col-md-6 input-container">
                                <input class="input-field" formControlName="LastName" type="text" placeholder="00">
                                <label class="input-label">Apellido(s)</label>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-12 col-md-6 input-container">
                                <input class="input-field" formControlName="PhoneNumber" type="text" placeholder="00"
                                    (keyup)="limit10($event)"
                                    [ngClass]="{'error': accountForm.controls['PhoneNumber'].touched && accountForm.controls['PhoneNumber'].errors?.required}"
                                    required>
                                <span
                                    *ngIf="accountForm.controls['PhoneNumber'].touched && accountForm.controls['PhoneNumber'].errors?.required"
                                    class="text-danger">
                                    Número de teléfono es requerido.
                                </span>
                                <span class="text-danger"
                                    *ngIf="accountForm?.controls['PhoneNumber'].touched && accountForm?.controls['PhoneNumber'].errors?.pattern">
                                    Ingresa sólo 10 números.
                                </span>
                                <label class="input-label">Número de teléfono</label>
                            </div>
                            <div class="col-12 col-md-6 input-container">
                                <input class="input-field" formControlName="Email" placeholder="validationCustom5"
                                    type="email"
                                    [ngClass]="{'error': accountForm?.controls['Email'].touched && accountForm?.controls['Email'].errors?.pattern || emailMatch}">
                                <span
                                    *ngIf="accountForm?.controls['Email'].touched && accountForm?.controls['Email'].value == ''"
                                    class="text-danger">
                                    El campo email es requerido.
                                </span>
                                <span *ngIf="accountForm?.controls['Email'].touched && emailMatch" class="text-danger">
                                    Ya existe un email registrado.
                                </span>
                                <label class="input-label">Correo electrónico</label>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-12 col-md-6 input-container">
                                <input class="input-field" formControlName="BusinessName" type="text" placeholder="123">
                                <label class="input-label">Razón Social</label>
                            </div>
                            <div class="col-12 col-md-6 input-container">
                                <input class="input-field" formControlName="Colabs" placeholder="validationCustom7"
                                    type="number"
                                    [ngClass]="{'error': accountForm?.controls['Colabs'].touched && accountForm?.controls['Colabs'].value== ''}">
                                <span
                                    *ngIf="accountForm?.controls['Colabs'].touched && accountForm?.controls['Colabs'].value == ''"
                                    class="text-danger">
                                    Número de colaboradores es requerido.
                                </span>
                                <label class="input-label">Número de Colaboradores</label>
                            </div>
                        </div>
                        <div class="form-group row" *ngIf="!onEdit">
                            <div class="col-12 col-md-6 input-container">
                                <input class="input-field" formControlName="Password" type="password" placeholder="00"
                                    [ngClass]="{'error': accountForm.controls['Password'].touched && accountForm.controls['Password'].errors?.required}"
                                    required>
                                <span
                                    *ngIf="accountForm.controls['Password'].touched && accountForm.controls['Password'].errors?.required"
                                    class="text-danger">
                                    La contraseña es requerida.
                                </span>
                                <label class="input-label">Contraseña</label>
                            </div>
                            <div class="col-12 col-md-6 input-container">
                                <input class="input-field" formControlName="newPassword" type="password"
                                    placeholder="00"
                                    [ngClass]="{'error': accountForm.controls['newPassword'].touched && accountForm.controls['newPassword'].errors?.required}"
                                    required>
                                <span *ngIf="accountForm.controls['newPassword'].touched" class=" text-danger">
                                    Las contraseñas no coinciden
                                </span>
                                <label class="input-label">Confirmar contraseña</label>
                            </div>
                        </div>
                        <div class="form-group col-12 mb-0 text-right">
                            <button [disabled]="isLoading" type="submit" (click)="onSubmit()" class="btn btn-red">
                                {{ onEdit ? 'Actualizar empresa' : 'Guardar empresa' }}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>