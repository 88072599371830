import { Injectable, HostListener, Inject } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { WINDOW } from "./windows.service";
// Menu
export interface Menu {
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
	rol?: string;
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	public screenWidth: any
	public collapseSidebar: boolean = false

	constructor(@Inject(WINDOW) private window) {
		this.onResize();
		if (this.screenWidth < 991) {
			this.collapseSidebar = true
		}
	}


	@HostListener("window:resize", ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

	MENUITEMS: Menu[] = [
	 { title: 'Dashboard', icon: 'home', type: 'link', badgeType: 'primary', rol: 'admin, business, users', path: '/dashboard', },
	
	 {title: 'Usuarios', icon: 'user', type: 'sub', active: false, rol: 'admin', 
	 children: [ { path: '/users/admins', title: 'Administradores', type: 'link' },
				/*{ path: '/users', title: 'Colaboradores', type: 'link' },  */]
		 }, 
		 { title: 'Empresas', icon: 'briefcase', type: 'link', active: false, path: '/empresa', rol: 'admin' },
		,
		 {title: 'Colaboradores', icon: 'users', type: 'link', active: false, path: '/colaboradores', rol: 'admin, business'},

		// { 
		// 	path: '/products/courses', title: 'Cursos', icon: 'shield-off', type: 'link', badgeType: 'primary', active: false,
		// },
		// {
		// 	title: 'Productos', icon: 'shield-off', type: 'sub', active: false, rol:'all', children: [
		// 		{ path: '/products/courses', title: 'Cursos', type: 'link' },
		// 		{ path: '/products/capacitations', title: 'Capacitaciones', type: 'link' },
		// 		{ path: '/products/webinars', title: 'Webinars', type: 'link' },
		// 		{ path: '/products/events', title: 'Eventos', type: 'link' },
		// 		{ path: '/products/catalogs', title: 'Catálogos', type: 'link' }
		// 	]
		// },
		// {
		// 	title: 'Cuestionarios', path: '/quizs', icon: 'list', type: 'link', badgeType: 'primary', active: false
		// },
		// {
		// 	title: 'Evaluaciones', path: '/evaluations', icon: 'check-circle', type: 'link', badgeType: 'primary', active: false
		// },
		/* {
			title: 'Rallys', path: '/rallys', icon: 'bookmark', type: 'link', badgeType: 'primary', active: false, rol: 'all'
		}, */
		// {
		// 	title: 'Noticias', path: '/news', icon: 'book-open', type: 'link', badgeType: 'primary', active: false
		// },
		// {
		// 	title: 'Wiki', path: '/wiki', icon: 'book', type: 'link', badgeType: 'primary', active: false
		// },
		// {
		// 	title: 'Ordenes', path: '/sales/orders', icon: 'shopping-cart', type: 'link', badgeType: 'primary', active: false
		// },
		// {
		// 	title: 'Pagos', path: '/sales/transactions', icon: 'dollar-sign', type: 'link', active: false
		// },
	/* 	{
			title: 'Ganadores', path: '/users/winners', icon: 'check-circle', type: 'link', active: false
		}, */
		/* {
			title: 'Usuarios', path: '/users', icon: 'users', type: 'link', active: false
		}, */
		// {
		// 	title: 'Carrera', icon: 'list', type: 'sub', active: false, rol: 'teacher', children: [
		// 		{ path: '/courses', title: 'Mis cursos', type:'link' },
		// 		{ path: '/students', title: 'Mis estudiantes', type:'link' }
		// 	]
		// },
		]

	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);


}
