<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <h4 class="pl-3 pt-4">{{ onEdit ? 'Editar' : 'Agregar' }} colaborador</h4>
                <div class="card-body tab2-card">
                    <div>
                        <form [formGroup]="accountForm" (ngSubmit)="createUser()" class="needs-validation user-add"
                            novalidate>
                            <div class="form-group row">
                                <div class="col-12 col-md-6 input-container">
                                    <input class="input-field" formControlName="firstName" placeholder="Nombre(s)"
                                        type="text" [ngClass]="{'error': submitted && accountForm.controls['firstName'].errors?.required
                                }">
                                    <div *ngIf="accountForm.controls['firstName'].touched && accountForm.controls['firstName'].errors?.required"
                                        class="text-danger">
                                        El campo nombre(s) es obligatorio.
                                    </div>
                                    <label class="input-label">Nombre(s)</label>
                                </div>
                                <div class="col-12 col-md-6 input-container">
                                    <input class="input-field" formControlName="lastName" placeholder="Apellido(s)"
                                        type="text" [ngClass]="{'error': submitted && accountForm.controls['lastName'].errors?.required
                                }">
                                    <div *ngIf="accountForm.controls['lastName'].touched && accountForm.controls['lastName'].errors?.required"
                                        class="text-danger">
                                        El campo apellido(s) es obligatorio.
                                    </div>
                                    <label class="input-label">Apellido(s)</label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-12 col-md-4 input-container">
                                    <input class="input-field" id="input-date" type="text"
                                        placeholder="Fecha de nacimiento" (change)="calculateAge($event)"
                                        (focus)="changeDate('input-date')" formControlName="dateOfBirth" [ngClass]="{'error': submitted && accountForm.controls['dateOfBirth'].errors?.required
                                                            }" />
                                    <label class="input-label">Fecha de nacimiento</label>
                                    <div *ngIf="accountForm.controls['dateOfBirth'].touched && accountForm.controls['dateOfBirth'].errors?.required"
                                        class="text-danger">
                                        El campo fecha de nacimiento es obligatorio.
                                    </div>
                                </div>
                                <div class="col-12 col-md-2 input-container">
                                    <input class="input-field" id="input-date" type="text" placeholder="Edad" disabled
                                        [value]="age" />
                                    <label class="input-label">Edad</label>
                                </div>
                                <div class="col-12 col-md-6 select-container">
                                    <select id="select-field2" name="gender" class="select-field"
                                        [ngClass]="{'error': submitted && accountForm.controls['gender'].errors?.required}"
                                        formControlName="gender" (change)="toggleLabel('select-field2')">
                                        <option value="" selected disabled hidden></option>
                                        <option value="masculino">Masculino</option>
                                        <option value="femenino">Femenino</option>
                                        <option value="otro">Otro</option>
                                    </select>
                                    <label class="select-label">Género</label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-12 col-md-6 input-container">
                                    <input class="input-field" placeholder="Empresa" [value]="name" disabled
                                        type="text">
                                    <label class="input-label">Empresa</label>
                                </div>
                                <div class="col-12 col-md-6 input-container">
                                    <input class="input-field" formControlName="position" placeholder="Cargo"
                                        type="text" [ngClass]="{'error': submitted && accountForm.controls['position'].errors?.required
                                }">
                                    <div *ngIf="accountForm.controls['position'].touched && accountForm.controls['position'].errors?.required"
                                        class="text-danger">
                                        El campo cargo es obligatorio.
                                    </div>
                                    <label class="input-label">Cargo</label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-12 col-md-6 input-container">
                                    <input class="input-field" formControlName="shift" placeholder="Turno" [ngClass]="{'error': submitted && accountForm.controls['shift'].errors?.required
                                }" />
                                    <div *ngIf="accountForm.controls['shift'].touched && accountForm.controls['shift'].errors?.required"
                                        class="text-danger">
                                        El turno es obligatorio.
                                    </div>
                                    <label class="input-label">Turno</label>
                                </div>
                                <div class="col-12 col-md-6 input-container">
                                    <input class="input-field" formControlName="schedule" placeholder="Horario"
                                        [ngClass]="{'error': submitted && accountForm.controls['schedule'].errors?.required
                                }" />
                                    <div *ngIf="accountForm.controls['schedule'].touched && accountForm.controls['schedule'].errors?.required"
                                        class="text-danger">
                                        El horario es obligatorio.
                                    </div>
                                    <label class="input-label">Horario</label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-12 col-md-6 input-container">
                                    <input class="input-field" formControlName="daysOff" placeholder="Días de descanso"
                                        [ngClass]="{'error': submitted && accountForm.controls['daysOff'].errors?.required
                                                                                            }" />
                                    <div *ngIf="accountForm.controls['daysOff'].touched && accountForm.controls['daysOff'].errors?.required"
                                        class="text-danger">
                                        Días de descanso es un dato obligatorio.
                                    </div>
                                    <label class="input-label">Días de descanso</label>
                                </div>
                                <div class="col-12 col-md-6">
                                    <input class="input-field" formControlName="phone" placeholder="Numero telefónico"
                                        type="tel" minlength="10" maxlength="10" [ngClass]="{'error': submitted && accountForm.controls['phone'].errors?.required
                                                            }" />
                                    <div *ngIf="accountForm.controls['phone'].touched && accountForm.controls['phone'].errors?.required"
                                        class="text-danger">
                                        El campo numero telefónico es obligatorio.
                                    </div>
                                    <label class="input-label">Número de teléfono</label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-12 col-md-6 input-container">
                                    <input class="input-field" formControlName="address" placeholder="Domicilio"
                                        [ngClass]="{'error': submitted && accountForm.controls['address'].errors?.required
                                }" />
                                    <div *ngIf="accountForm.controls['address'].touched && accountForm.controls['address'].errors?.required"
                                        class="text-danger">
                                        Domicilio es un campo obligatorio.
                                    </div>
                                    <label class="input-label">Domicilio</label>
                                </div>
                                <div class="col-12 col-md-6 input-container">
                                    <input class="input-field" formControlName="jobSeniority"
                                        placeholder="Antigüedad laboral" [ngClass]="{'error': submitted && accountForm.controls['jobSeniority'].errors?.required
                                }" />
                                    <div *ngIf="accountForm.controls['jobSeniority'].touched && accountForm.controls['jobSeniority'].errors?.required"
                                        class="text-danger">
                                        Antigüedad es un campo obligatorio.
                                    </div>
                                    <label class="input-label">Antigüedad laboral</label>
                                </div>
                            </div>
                            <div class="form-group col-12 mb-0 text-right">
                                <button class="btn btn-red" type="submit" (click)="createUser()" [disabled]="isLoading"
                                    *ngIf="onEdit == false">
                                    {{ isLoading ? 'Guardando' : 'Guardar'}}
                                    <i class="fa fa-spinner fa-spin" [hidden]="!isLoading"></i>
                                </button>
                                <button type="submit" class="btn btn-red" *ngIf="onEdit == true">
                                    {{ isLoading ? 'Editando' : 'Editar'}}
                                    <i class="fa fa-spinner fa-spin" [hidden]="!isLoading"></i>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->